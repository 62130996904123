<template>
  <v-footer class="bg-grey-lighten-5">
    <v-container class="text-center py-4">
      <div class="mb-2">
        © {{ new Date().getFullYear() }} — <strong>Eduworky</strong>. Tous droits réservés.
      </div>
      <div class="mt-4">
        <v-icon small class="mx-2">mdi-facebook</v-icon>
        <v-icon small class="mx-2">mdi-twitter</v-icon>
        <v-icon small class="mx-2">mdi-linkedin</v-icon>
      </div>
    </v-container>
  </v-footer>
</template>

<script setup>

</script>

<style scoped>

</style>